
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "PromoPT15USDCongratulations",
  components: {},
})
export default class PromoPT15USDCongratulations extends Vue {
  mounted() {
    this.$jfNotification.clearAll();
  }
}
